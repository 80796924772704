import { getRule } from '@/utils/rules';
const oarSchema = [
  {
    label: 'Expenses',
    value: 'expensesPerAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Lbs',
    value: 'lbsPerAcre',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '/Acre',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Price',
    value: 'pricePerLB',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/Lb.',
    saleTypes: ['Sale','Lease'],
  },
];
export default oarSchema;
