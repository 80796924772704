import { getRule, removeCommas } from '@/utils/rules';

const financialSchema = [
  {
    label: 'PGI',
    value: 'PGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { vacancy } = dataRecord.financial;
      if(!vacancy) {
        vacancy = 0;
      } else {
        vacancy = parseFloat(removeCommas(vacancy));
      }
      let { expenditure } = dataRecord.financial;
      console.log('finacial record');
      console.log(expenditure);
      console.log(dataRecord.financial);
      expenditure = -1 * removeCommas(expenditure);
      console.log(`negative expendiature ${expenditure}`);
      if(!expenditure) {
        console.log('NO Expenditure');
        expenditure = 0;
      }
      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      }
      const EGI = removeCommas(value) * (1 - removeCommas(vacancy)/100);
      console.log(`initial expenditure ${expenditure}`);
      console.log(1 - removeCommas(vacancy)/100)
      console.log(`${removeCommas(value)} * ${(1 - removeCommas(vacancy)/100)}`);
      console.log(`EGI ${EGI}`);
      const NOI = parseFloat(removeCommas(EGI)) + parseFloat(removeCommas(expenditure));

      const OAR = ((parseFloat(removeCommas(NOI)) / parseFloat(removeCommas(priceSale))))* 100;
      const OER = (((parseFloat(removeCommas(expenditure)) * -1) / parseFloat(removeCommas(EGI)) ) * 100);
      console.log('');
      console.log('*********');
      console.log(`salePrice ${priceSale}`);
      console.log(`calced OAR to ${OAR}`);
      console.log(`calced OER to ${OER}`);
      console.log(`calced NOI to ${NOI}`);
      console.log(`${parseFloat(removeCommas(EGI))} - ${parseFloat(removeCommas(expenditure))}`)
      console.log('*********');
      console.log('');

      await saveRecord('financial', 'EGI' , EGI, id, record, true)
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'Vacancy',
    value: 'vacancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      value = value * 100;
      let { PGI } = dataRecord.financial;
      if(!PGI) {
        PGI = 0;
      }
      let { expenditure } = dataRecord.financial ;
      expenditure = -1 * removeCommas(expenditure);
      if(!expenditure) {
        expenditure = 0;
      }
      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      }
      const EGI = removeCommas(PGI) * (1 - removeCommas(value)/100);
      const NOI = parseFloat(removeCommas(EGI)) + parseFloat(removeCommas(expenditure));
      await saveRecord('financial', 'EGI' , EGI, id, record, true)
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = (NOI / removeCommas(priceSale)) * 100;
      const OER = ((expenditure * -1) / EGI) * 100;
      console.log(`NOI: ${NOI}, priceSale ${priceSale}`);
      console.log(`OAR: ${OAR}, OER: ${OER}`);
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }

  },
  {
    label: 'EGI',
    value: 'EGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { PGI, expenditure } = dataRecord.financial;
      if(!PGI) {
        PGI = 0;
      } else {
        PGI = parseFloat(removeCommas(PGI));
      }
      if(!expenditure) {
        expenditure = 0;
      } else {
        expenditure = parseFloat(removeCommas(expenditure));
      }

      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }
      value = parseFloat(removeCommas(value));

      const EGI = value;
      const NOI = EGI - expenditure;

      const OAR = (NOI / priceSale) * 100;
      const OER = (expenditure) / EGI * 100;
      const vacancy = ((PGI - EGI) / PGI) * 100;


      console.log('*********');
      console.log(`salePrice ${priceSale}`);
      console.log(`calced OAR to ${OAR}`);
      console.log(`calced OER to ${OER}`);
      console.log(`calced NOI to ${NOI}`);
      console.log(`calced vacancy to ${vacancy} = ${EGI} / ${PGI}`);
      console.log('*********');

      await saveRecord('financial', 'NOI', NOI, id, record, true);
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
      await saveRecord('financial', 'vacancy', vacancy, id, record, true);
    }
  },
  {
    label: 'Expenditures',
    value: 'expenditure',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let { EGI, expenditure } = dataRecord.financial;
      expenditure = -1 * removeCommas(expenditure);
      if(!EGI) {
        EGI = 0;
      }
      if(!expenditure) {
        expenditure = 0;
      }
      console.log(EGI);

      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      }

      const NOI = parseFloat(removeCommas(EGI)) - parseFloat(removeCommas(value));
      console.log(NOI);
      await saveRecord('financial', 'NOI', NOI, id, record, true);
      const OAR = (NOI / removeCommas(priceSale))* 100;
      const OER = ((parseFloat(removeCommas(expenditure)) * -1) / parseFloat(removeCommas(EGI)) * 100);
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'NOI', 
    value: 'NOI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let { EGI } = dataRecord.financial;
      if(!EGI) {
        EGI = 0;
      }
      let { priceSale } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      }
      const expenditure = -1 * (parseFloat(removeCommas(value)) - parseFloat(removeCommas(EGI)));
      await saveRecord('financial', 'expenditure', expenditure, id, record, true);
      const OAR = (value / removeCommas(priceSale)) * 100;
      const OER = ((parseFloat(removeCommas(expenditure))) / parseFloat(removeCommas(EGI)) * 100);
      await saveRecord('financial', 'OAR', OAR, id, record, true);
      await saveRecord('financial', 'OER', OER, id, record, true);
    }
  },
  {
    label: 'OAR',
    value: 'OAR',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'OER',
    value: 'OER',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Source',
    value: 'source',
    type: 'switch',
    indicators: {
			0: 'Estimated',
			1: 'Actual',
		},
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
		label: 'Lease Type',
    value: 'leaseType',
    type: 'select',
    items: [
			'NNN',
			'Gross',
			'Modified Gross',
			'Full Service',
		],
    subCategories: [
      'Aparment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
	}		
];
export default financialSchema;
