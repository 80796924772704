import { getRule } from '@/utils/rules';
const unitMixSchema = [
  {
    label: 'Type',
    value: 'type',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Number of Units',
    value: 'number',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Percentage Of Units',
    value: 'percentageOfUnits',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Unit SF',
    value: 'unitSF',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: 'SF',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Unit Rent',
    value: 'unitRent',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Unit Rent/SF',
    value: 'unitRentSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale','Lease'],
  },
];
export default unitMixSchema;
