import { getRule } from '@/utils/rules';
import { calcValues } from '@/utils/calcs.js';

const improvementItemsSchema = [
  {
    label: 'Type',
    value: 'type',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcValues(value, id, record, saveRecord, dataRecord);
    }
  },
  {
    label: 'Age',
    value: 'age',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Size',
    value: 'size',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Cost New',
    value: 'costNew',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      console.log('calc cost new');
      console.log(value);
      console.log(id);
      console.log(record);
      console.log(saveRecord);
      console.log(dataRecord);
      const { improvementItems } = dataRecord;
      if(improvementItems) {
        const itemRecord = improvementItems.reduce((i, j) => {
          if(j.id === id) {
            return j;
          }
          return i;
        }, null);
        if(itemRecord) {
          itemRecord.contributingValue = value * .5;

          await saveRecord('improvementItems', 'contributingValue', value * .5, id, record, true)
        }
      }
    },
  },
  {
    label: 'Contributing Value',
    value: 'contributingValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcValues(value, id, record, saveRecord, dataRecord);
    }
  },
  {
    label: 'Value/SF',
    value: 'valueSF',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '/SF',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Depreciation Percent',
    value: 'depreciationPercent',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Effective Age',
    value: 'effectiveAge',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
];
export default improvementItemsSchema;
