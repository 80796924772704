import { getRule } from '@/utils/rules';
const irrSchema = [
  {
    label: 'Age',
    value: 'age',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Water Supply',
    value: 'waterSupply',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'CFO',
    value: 'CFO',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
];
export default irrSchema;
