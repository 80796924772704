import { getRule, removeCommas } from '@/utils/rules';

const calcActualValues = async (value, id, record, saveRecord, dataRecord, actualProForma, fieldName) => {
  const { apartmentFinancial } = dataRecord;
  const itemRecord = apartmentFinancial;
  console.log('************** Apartment Financial Calc *********************')
  if(itemRecord) {
    if(apartmentFinancial) {
      if(actualProForma) {
        console.log(`calc Actual ${record}`);
        let { aExpenses, aUnitIncome, aOtherIncome } = itemRecord;
        let { priceSale } = dataRecord.transactional;

        console.log('unit income');
        if(fieldName == 'aUnitIncome') {
          aUnitIncome = parseFloat(removeCommas(value));
        } else {
          if(!aUnitIncome) {
            aUnitIncome = 0;
          } else {
            aUnitIncome = parseFloat(removeCommas(aUnitIncome));
          }
        }
        console.log(aUnitIncome)

        console.log('other income');
        if(fieldName == 'aOtherIncome') {
          aOtherIncome = parseFloat(removeCommas(value));
        } else {
          if(!aOtherIncome) {
            aOtherIncome = 0;
          } else {
            aOtherIncome = parseFloat(removeCommas(aOtherIncome));
          }
        }
        console.log(aOtherIncome)

        console.log('expenses');
        if(fieldName == 'aExpenses') {

          aExpenses = parseFloat(removeCommas(value));
        } else {
          if(!aExpenses) {
            aExpenses = 0;
          } else {
            aExpenses = parseFloat(removeCommas(aExpenses));
          }
        }
        console.log(aExpenses);

        console.log('VC');        
        let { aVC } = itemRecord;
        if(fieldName == 'aVC') {
          aVC = parseFloat(removeCommas(value));
        } else {
          if(!aVC) {
            aVC = 0;
          } else {
            aVC = parseFloat(removeCommas(aVC));
          }
        }
        console.log(aVC)

        console.log('sale price');
        if(fieldName == 'priceSale') {
          priceSale = parseFloat(removeCommas(value));
        } else {
          if(!priceSale) {
            priceSale = 0;
          }
        }
        console.log(priceSale);
       

        const newAPGI = aUnitIncome + aOtherIncome;

        const newAEGI = newAPGI + aVC;
        console.log(`newAEGI: ${newAPGI + aVC} = ${newAPGI} + ${aVC}`);
        await saveRecord('apartmentFinancial', 'aPGI', newAPGI, id, record, true);
        
        await saveRecord('apartmentFinancial', 'aEGI', newAEGI, id, record, true);

        const newNOI = aExpenses + newAEGI;
        await saveRecord('apartmentFinancial', 'aNoi', newNOI, id, record, true)

        const aEGIRatio = (parseInt((aExpenses / newAEGI) * 10000) / 100) * -1;
        await saveRecord('apartmentFinancial', 'aEGIRatio', aEGIRatio, id, record, true);

        const aCapRate = (newNOI / removeCommas(priceSale)) * 100;
        await saveRecord('apartmentFinancial', 'aCAPRate', aCapRate, id, record, true);

      } else {
        console.log('calc proForma');
        let { pExpenses, pUnitIncome, pLossToLease, pOtherIncome } = itemRecord;
        let { priceSale } = dataRecord.transactional;


        console.log('PLossTolease');
        if(fieldName == 'pLossToLease') {
          pLossToLease = parseFloat(removeCommas(value));
        } else {
          if(!pLossToLease) {
            pLossToLease = 0;
          } else {
            pLossToLease = parseFloat(removeCommas(pLossToLease));
          }
        }
        console.log(pLossToLease);

        console.log('pUnitIncome');
        if(fieldName == 'pUnitIncome') {
          pUnitIncome = parseFloat(removeCommas(value));
        } else {
          if(!pUnitIncome) {
            pUnitIncome = 0;
          } else {
            pUnitIncome = parseFloat(removeCommas(pUnitIncome));
          }
        }
        console.log(pUnitIncome);

        console.log('other income');
        if(fieldName == 'pOtherIncome') {
          pOtherIncome = parseFloat(removeCommas(value));
        } else {
          if(!pOtherIncome) {
            pOtherIncome = 0;
          } else {
            pOtherIncome = parseFloat(removeCommas(pOtherIncome));
          }
        }
        console.log(pOtherIncome)

        console.log('pExpenses');
        if(fieldName == 'pExpenses') {
          pExpenses = parseFloat(removeCommas(value));
        } else {
          if(!pExpenses) {
            pExpenses = parseFloat(removeCommas(value));
          } else {
            pExpenses = parseFloat(removeCommas(pExpenses));
          }
        }
        console.log(pExpenses);

        console.log('VC');        
        let { pVC } = itemRecord;
        if(fieldName == 'pVC') {
          pVC = parseFloat(removeCommas(value));
        } else {
          if(!pVC) {
            pVC = 0;
          } else {
            pVC = parseFloat(removeCommas(pVC));
          }
        }
        console.log(pVC)

        console.log('sale price');
        if(fieldName == 'priceSale') {
          priceSale = parseFloat(removeCommas(value));
        } else {
          if(!priceSale) {
            priceSale = 0;
          } else {
            priceSale = parseFloat(removeCommas(priceSale))
          }
        }
        console.log(priceSale);

        const newValue = itemRecord.pOtherIncome + itemRecord.PPgi + itemRecord.pLossToLease + itemRecord.pVc;
        itemRecord.pEgi = newValue;
        const newPPGI = pUnitIncome + pOtherIncome;


        const newPEGI = newPPGI + pVC+ pLossToLease;
        await saveRecord('apartmentFinancial', 'pPGI', newPPGI, id, record, true);
        
        await saveRecord('apartmentFinancial', 'pEGI', newPEGI, id, record, true);

        const newNOI = pExpenses + newPEGI;
        await saveRecord('apartmentFinancial', 'pNoi', newNOI, id, record, true)

        const pEGIRatio = (parseInt((pExpenses / newPEGI) * 10000) / 100) * -1;
        await saveRecord('apartmentFinancial', 'pEGIRatio', pEGIRatio, id, record, true);

        const pCapRate = (newNOI / removeCommas(priceSale)) * 100;
        await saveRecord('apartmentFinancial', 'pCAPRate', pCapRate, id, record, true);
      }
    }
  }
}

const apartmentFinancialSchema = [
  {
    label: 'Actual Source',
    value: 'aSource',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Pro Forma Source',
    value: 'pSource',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Unit Income',
    value: 'aUnitIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, true, 'aUnitIncome');
    }
  },
  {
    label: 'Pro Forma Unit Income',
    value: 'pUnitIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, false, 'pUnitIncome');
    }
  },
  {
    label: 'Actual Other Income',
    value: 'aOtherIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, true, 'aOtherIncome');
    }
  },
  {
    label: 'Pro Forma Other Income',
    value: 'pOtherIncome',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, false, 'pOtherIncome');
    }
  },
  {
    label: 'Actual PGI',
    value: 'aPGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Pro Forma PGI',
    value: 'pPGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Actual Loss To Lease',
    value: 'aLossToLease',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,

  },
  {
    label: 'Pro Forma Loss To Lease',
    value: 'pLossToLease',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, false, 'pLossToLease');
    }
  },
  {
    label: 'Actual VC',
    value: 'aVC',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, true, 'aVC');
    }
  },
  {
    label: 'Pro Forma VC',
    value: 'pVC',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, false, 'pVC');
    }
  },
  {
    label: 'Actual EGI',
    value: 'aEGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false

  },
  {
    label: 'Pro Forma EGI',
    value: 'pEGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Actual Expenses',
    value: 'aExpenses',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, true, 'aExpenses');
    }
  },
  {
    label: 'Pro Forma Expenses',
    value: 'pExpenses',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      await calcActualValues(value, id, record, saveRecord, dataRecord, false, 'pExpenses');
    }
  },
  {
    label: 'Actual NOI',
    value: 'aNoi',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Pro Forma NOI',
    value: 'pNoi',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Actual Expense Ratio',
    value: 'aEGIRatio',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Pro Forma EGI Ratio',
    value: 'pEGIRatio',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Actual CAP Rate',
    value: 'aCAPRate',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Pro Forma CAP Rate',
    value: 'pCAPRate',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
];
export default apartmentFinancialSchema;
