<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :item-class="getRowClass"
      :items-per-page="itemsPerPage"
      multi-sort
      show-select
      :loading="loading"
      :search="`${sDelimnitor}${search}`"
      :custom-filter="filterItems"
      @item-selected="selectedItem"
      @toggle-select-all="selectAllItems"
      v-model="selectedRows"
      @click:row="showPropertyCard"
    >
    </v-data-table>
    <div @contextmenu.prevent="showContextMenu">
      <propertyCard
        :displayComponent="propertyCardShown"
        :style="{
          position: 'absolute',
          top: `${propertyCardData.y - 325}px`,
          left: `${propertyCardData.x - 160}px`,
        }"
        class="propertyCard"
        :id="propertyCardData.id"
        :salePrice="propertyCardData.salePrice"
        :saleDate="propertyCardData.saleDate"
        :otherData="propertyCardData.otherData"
        :imageId="propertyCardData.imageId"
        :address="propertyCardData.address"
        :acreage="propertyCardData.acres"
        :selectedItems="selectedItems"
        :tableData="true"
        :currentView="'tableView'"
        :loadingData="loadingData"
        @close="propertyCardShown = false"
        @clickCard="openProperty(propertyCardData.id)"
        @deleteCard="$emit('deleteProperty', propertyCardData.id)"
        @selectCard="selectItemFromId(propertyCardData.id)"
      />
    </div>

    <v-menu
      v-model="contextMenuShown"
      :position-x="contextMenuX"
      :position-y="contextMenuY"
      absolute
      offset-y
      style="z-index: 9999"
    >
      <div style="background: white">
        <v-list-item v-for="(item, index) in contextMenuItems" :key="index">
          <button>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </button>
        </v-list-item>
      </div>
    </v-menu>
  </v-card>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import propertyCard from "@/components/propertyCard";

export default defineComponent({
  name: "tableData",
  components: { propertyCard },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    filterOpen: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const sDelimnitor = "|searchme|";
    const itemsPerPage = ref(-1);
    const setSelectedRows = () => {
      return props.items.filter((i) => {
        console.log(i);
        return props.selectedItems.find((j) => j === i.id) ? true : false;
      });
    };
    const selectedRows = ref(setSelectedRows());
    console.log('table');
    console.log(props);
    const search = ref("");
    const filterItems = (value, search, item) => {
      console.log('item');
      console.log(item);
      //eslint-disable-line
      /*
      const oSearch = search.substring(
        search.indexOf(sDelimnitor) + sDelimnitor.length
      );
      const sTest = Object.keys(item).reduce((i, j) => {
        if (String(item[j]).indexOf(oSearch) + 1 || i) {
          return true;
        }
        return false;
      }, false);
      */

      let filtered = true;

      if (~~props.filter.minSale && ~~item.salePrice) {
        console.log('filter minsale');
        if (~~props.filter.minSale <= ~~item.salePrice) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minSale ${filtered ? 'true' : 'false'}`);
      }


      if (~~props.filter.maxSale && ~~item.salePrice) {
        if (~~props.filter.maxSale >= ~~item.salePrice) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxSale ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.minAcres && ~~item.acreage) {
        console.log(`minAcres ${~~props.filter.minAcres} <= ${~~item.acreage}`);
        if (~~props.filter.minAcres <= ~~item.acreage) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minAcres ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.maxAcres && ~~item.acreage) {
        console.log(`maxAcres ${~~props.filter.maxAcres} >= ${~~item.acreage}`);
        if (~~props.filter.maxAcres >= ~~item.acreage) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxAcres ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.minSqft && item.sqft) {
        if (~~props.filter.minSqft <= ~~item.minSqft) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minSqft ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.maxSqft && item.sqft) {
        if (~~props.filter.maxSqft >= ~~item.sqft) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxSqft ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.dateFrom) {
        if (new Date(props.filter.dateFrom) >= new Date(item.saleDate)) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`dateFrom ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.dateTo) {
        if (new Date(props.filter.dateTo) <= new Date(item.dateTo)) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`dateTo ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.apn) {
        if (String(item.apn).indexOf(String(props.filter.apn)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`apn ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.city) {
        if (String(item.city).indexOf(String(props.filter.city)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`city ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.county) {
        if (String(item.county).indexOf(String(props.filter.county)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`county ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.address) {
        if (String(item.address).indexOf(String(props.filter.address)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`address ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.plantings) {
        if (String(item.plantings).indexOf(String(props.filter.plantings)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`plantings ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.openGround) {
        if (String(item.openGround).indexOf(String(props.filter.openGround)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`openGround ${filtered ? 'true' : 'false'}`);
      }

      if(props.filter.category) {
        if(item.category == props.filter.category) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`category ${filtered ? 'true' : 'false'}`);
      }

      if(props.filter.subcategory) {
        if(item.subcategory == props.filter.subcategory) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`subcategory ${filtered ? 'true' : 'false'}`);
      }

      if(item.propertyType != props.filter.propertyType) {
        filtered = false;
      }


      console.log(`filter finished ${filtered ? 'true' : 'false'}`);
      console.log('');
      return filtered;
    };

    const selectedItem = (selected) => {
      console.log("selected item");
      console.log(selected);
      emit("updateSelectedItem", selected.item, selected.value);
    };

    const contextMenuItems = [
      { text: "Copy", icon: "mdi-eye" },
      { text: "Duplicate", icon: "mdi-pencil" },
    ];
    const contextMenuShown = ref(false);
    const contextMenuX = ref(0);
    const contextMenuY = ref(0);
    const showContextMenu = (event) => {
      contextMenuShown.value = false;
      contextMenuX.value = event.clientX;
      contextMenuY.value = event.clientY;
      contextMenuShown.value = true;
    };

    const selectAllItems = (selected) => {
      for (const inum in selected.items) {
        emit("updateSelectedItem", selected.items[inum], selected.value);
      }
    };

    const propertyCardData = ref({
      showMe: false,
      X: 0,
      y: 0,
      imageId: null,
      address: "No Address",
      otherData: "N/A",
      salePrice: "No Price",
      saleDate: "No Date",
      acres: "N/A",
    });

    const propertyCardShown = ref(false);
    const currentPropertyShown = ref(null);
    const getRowClass = (item) => {
      return item === currentPropertyShown.value ? "selected" : "tableRow";
    };

    const openProperty = (propCardId) => {
      propertyCardShown.value = false;
      emit("openProperty", propCardId);
    };

    const showPropertyCard = (item) => {
      console.log(`show Property Card: ${event.clientX} => ${event.clientY}`);
      console.log(item);
      currentPropertyShown.value = item;
      propertyCardData.value = {
        id: null,
        showMe: false,
        x: props.filterOpen ? event.clientX - 500 : event.clientX,
        y: event.clientY,
        acreage: "500",
        imageId: item.imageId,
        address: "No Address",
        otherData: "N/A",
        salePrice: "No Price",
        saleDate: "No Date",
        acres: "N/A",
      };
      if (item.id) {
        propertyCardData.value.id = String(item.id);
      }
      if (item.imageId) {
        propertyCardData.value.imageId = String(item.imageId);
      }
      if (item.salePrice) {
        propertyCardData.value.salePrice = String(item.salePrice);
      }
      if (item.address) {
        propertyCardData.value.address = String(item.address);
      }
      if (item.waterDistrict) {
        propertyCardData.value.otherData = String(item.waterDistrict);
      }
      if (item.saleDate) {
        propertyCardData.value.saleDate = String(item.saleDate);
      }
      if (item.acreage) {
        propertyCardData.value.acres = String(item.acreage);
      }
      propertyCardShown.value = true;
    };
    watch(props.selectedItems, () => {
      selectedRows.value = setSelectedRows();
    });
    watch(props.filter, () => {
      //Hack to reset table data
      const sValue = search.value;
      search.value = " ";
      search.value = sValue;
    });

    const selectItemFromId = (itemID) => {
      console.log('select item');
      const item = props.items.reduce((i,j) => {
        if(j.id == itemID) {
          return j;
        }
        return i;
      },null);
      if(item) {
        emit("updateSelectedItem", item, true);
      }
    }

    return {
      selectItemFromId,
      sDelimnitor,
      propertyCardData,
      propertyCardShown,
      openProperty,
      showPropertyCard,
      filterItems,
      selectedRows,
      search,
      selectedItem,
      selectAllItems,
      itemsPerPage,
      getRowClass,
      showContextMenu,
      contextMenuItems,
      contextMenuShown,
      contextMenuX,
      contextMenuY,
    };
  },
});
</script>
<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.pageHeight {
  height: 200px !important;
  overflow: scroll;
}
.propertyCard {
  z-index: 200;
  position: absolute;
}

:deep(.tableRow) {
  td {
    cursor: pointer;
  }
}

:deep(.selected) {
  :hover,
  td {
    background-color: rgb(206, 219, 255);
    cursor: pointer;
  }
}
</style>
