      const tableSchema = [
        //Begin Side By Side 2x2 propertyInfo / attachments
        { 
          name: 'properties',
          title: 'Property Information',
          type: 'bubbleView',
          cols: 6,
          subCategories: [
            'Land',
            'Apartment',
            'Dairy',
            'Open Ground',
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'attachments',
          title: 'Attachments',
          type: 'attachmentsView',
          cols: 6,
          subCategories: [
            'Land',
            'Apartment',
            'Dairy',
            'Open Ground',
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        //Begin Side by Side 3x2 
        { 
          name: 'properties',
          title: 'Property Information',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
            'Self Storage',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'attachments',
          title: 'Attachments',
          type: 'attachmentsView',
          cols: 4,
          subCategories: [
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
            'Self Storage',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'siteInfo',
          title: 'Site Info',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
            'Self Storage',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'apartmentFinancial',
          title: 'Apartment Financial',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Apartment',
            'test'
          ],
          saleTypes: [
            'Sale',
          ],
        },
        {
          name: 'transactional',
          title: 'Transactional Data',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Self Storage',
            'Shopping',
            'Industrial',
            'Restaurant',
            'Land',
            'Office',
            'Retail',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },

        {
          name: 'financial',
          title: 'Financial',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Restaurant',
            'Office',
            'Retail',
            'Industrial',
            'Shopping',
            'Self Storage',
            'test',
          ],
          saleTypes: [
            'Sale',
          ],
        },

        //Being Financial / Transactional Pair
        {
          name: 'financial',
          title: 'Financial',
          type: 'bubbleView',
          cols: 6,
          subCategories: [
            'Medical',
          ],
          saleTypes: [
            'Sale',
          ],
        },
        {
          name: 'transactional',
          title: 'Transactional Data',
          type: 'bubbleView',
          cols: 6,
          subCategories: [
            'Medical',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        //End Financial / Transactional Pair

        //Begin full width land layout
        {
          name: 'siteInfo',
          title: 'Site Info',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Land',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        //End full width land layout

        {
          name: 'transactional',
          title: 'Transactional Data',
          type: 'bubbleView',
          cols: 8,
          subCategories: [
            'Apartment',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        // Siteinfo after transactional data 
        {
          name: 'siteInfo',
          title: 'Site Info',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Apartment'
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        //Begin Side by Side transactional / siteinfo
        {
          name: 'transactional',
          title: 'Transactional Data',
          type: 'bubbleView',
          cols: 6,
          subCategories: [
            'Dairy',
            'Open Ground',
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'siteInfo',
          title: 'Site Info',
          type: 'bubbleView',
          cols: 6,
          subCategories: [
            'Dairy',
            'Open Ground',
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        //End Side by Side transactional / siteinfo

        {
          name: 'plantings',
          title: 'Plantings',
          type: 'tableView',
          cols: 12,
          subCategories: [
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },

        {
          name: 'improvements',
          title: 'Improvements',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Apartment',
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
						'Dairy',
            'Self Storage',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'improvementItems',
          title: 'Improvements',
          type: 'tableView',
          cols: 12,
          subCategories: [
            'Open Ground',
            'Permanent Plantings',
            'Dairy',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'leaseInformation',
          title: 'Lease Information',
          type: 'tableView',
          cols: 12,
          subCategories: [
            'Apartment',
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
            'test',
          ],
          saleTypes: [
            'Sale',
          ],
        },
        {
          name: 'unitMix',
          title: 'Unit Mix',
          type: 'tableView',
          cols: 12,
          subCategories: [
            'Apartment',
            'Self Storage',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'remarks',
          title: 'Remarks',
          type: 'bubbleView',
          cols: 12,
          subCategories: [
            'Apartment',
            'Land',
            'Medical',
            'Office',
            'Restaurant',
            'Retail',
            'Industrial',
            'Shopping',
            'Self Storage',
            'Dairy',
            'Open Ground',
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'oar',
          title: 'OAR',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'appreciation',
          title: 'Appreciation/Depreciation',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },
        {
          name: 'irr',
          title: 'IRR',
          type: 'bubbleView',
          cols: 4,
          subCategories: [
            'Permanent Plantings',
            'test',
          ],
          saleTypes: [
            'Sale',
            'Lease',
          ],
        },

      ];
export default tableSchema;
