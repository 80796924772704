import { getRule, addCommas, removeCommas } from '@/utils/rules';

const SQUARE_FT_PER_ACRE = 43560
const siteInfoSchema = [
  {
    label: 'Site Area Acres',
    value: 'siteAreaAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    calc: async (value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaSqft', addCommas(value * SQUARE_FT_PER_ACRE), id, record, true)
    },
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Site Area SF',
    value: 'siteAreaSqft',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'SF',
    calc: async(value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaAcres', addCommas(value / SQUARE_FT_PER_ACRE), id, record, true)
    },
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Total Acres /Acre',
    value: 'totalAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    saleTypes: ['Sale','Lease'],
    calc: async(value, id, record, saveRecord, dataRecord) => {
      let totalAcres = parseFloat(removeCommas(value));
      let { landValuePerAcre } = dataRecord.siteInfo;
      let { priceSale, id:transactionalId, plantingsLandValue, ogLandValue, dairyLandValue } = dataRecord.transactional;
      if(!totalAcres) {
        totalAcres = 1;
      }
      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }

      let landValue = 0;
      if(!landValue) {
        landValue = parseFloat(removeCommas(plantingsLandValue));
      }

      if(!landValue) {
        landValue = parseFloat(removeCommas(ogLandValue));
      }
      if(!landValue) {
        landValue = parseFloat(removeCommas(dairyLandValue));
      }

      console.log(`${landValuePerAcre = landValue / totalAcres} = ${landValue} / ${totalAcres}`);
      landValuePerAcre = priceSale / totalAcres;

      console.log('calced land value per acre');
      console.log(landValuePerAcre);
      await saveRecord('siteInfo', 'landValuePerAcre', landValuePerAcre, id, record, true)


      
      console.log(id);
      console.log(record);
      console.log(saveRecord.length);
      const { siteInfo } = dataRecord;
      let { dairySite } = siteInfo;
      if(siteInfo && dairySite) {
        if(!dairySite) {
          dairySite = 0;
        } else {
          dairySite = parseFloat(removeCommas(dairySite));
        }

        console.log('***** calc dairy site *****');
        console.log(` dairySite: ${dairySite}, totalAcres: ${value}`);
        
        const percentSiteUsed = ((dairySite / value ) * 10000) / 100;

        await saveRecord('siteInfo', 'percentSiteUsed', percentSiteUsed, id, record, true);

        let totalAcres = parseFloat(removeCommas(value));

        if(!priceSale) {
          priceSale = 0;
        } else {
          priceSale = parseFloat(removeCommas(priceSale));
        }
        if(!totalAcres) {
          totalAcres = 1;
        }
        const priceAcre = priceSale / totalAcres;
        console.log(`update price per acre ${priceAcre}`)
        await saveRecord('transactional', 'priceAcre', priceAcre, transactionalId, record, true);




      }
    },
  },
  {
    label: 'GSA',
    value: 'GSA',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Planted Acres',
    value: 'plantedAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    saleTypes: ['Sale','Lease'],
    calc: async(value, id, record, saveRecord, dataRecord) => {
      let plantedAcres = parseFloat(removeCommas(value));
      if(!plantedAcres) {
        plantedAcres = 1;
      }
      const { transactional } = dataRecord;
      let { plantingsLandValue } = transactional;
      if(!plantingsLandValue) {
        plantingsLandValue = 0
      } else {
        plantingsLandValue = parseFloat(removeCommas(plantingsLandValue));
      }
      const pricePerPlantedAcre = plantingsLandValue / plantedAcres;
      await saveRecord('siteInfo', 'pricePerPlantedAcre', pricePerPlantedAcre, id, record, true)
    }
  },
  {
    label: 'Pumping Restrictions',
    value: 'pumpingRestrictions',
    type: 'checkbox',
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Sub Basin',
    value: 'subBasin',
    type: 'text',
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Total Price Per Acre',
    value: 'landValuePerAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },

  {
    label: 'Price Per Planted Acre',
    value: 'pricePerPlantedAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Permanent Plantings',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },

  {
    label: 'Site Coverage Ratio',
    value: 'siteCoverageRatio',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Surplus Land',
    value: 'surplusLand',
    type: 'checkbox',
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Site Allocation',
    value: 'siteAllocation',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Off Sites',
    value: 'offSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'On Sites',
    value: 'onSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Shape',
    value: 'shape',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Topography',
    value: 'topography',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },


  {
    label: 'Dairy Site',
    value: 'dairySite',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let dairySite = parseFloat(removeCommas(value));
      const { siteInfo } = dataRecord;
      let { totalAcres, actualMilking } = siteInfo;
      if(!actualMilking) {
        actualMilking = 0;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }

      if(!dairySite) {
        dairySite = 1;
      }

      const cowDairySiteAc = actualMilking / dairySite
      await saveRecord('siteInfo','cowDairySiteAc', cowDairySiteAc, id, record, true);

      if(siteInfo && totalAcres) {
        if(!totalAcres) {
          totalAcres = 0;
        } else {
          totalAcres = parseFloat(removeCommas(totalAcres));
        }

        console.log('***** calc dairy site *****');
        console.log(` dairySite: ${value}, totalAcres: ${totalAcres}`);
        
        const percentSiteUsed = ((value / totalAcres ) * 10000) / 100;

        await saveRecord('siteInfo', 'percentSiteUsed', percentSiteUsed, id, record, true);
      }
      

    },
  },
  {
    label: 'Percent Site Used',
    value: 'percentSiteUsed',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Farm Land',
    value: 'farmLand',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Actual Milking',
    value: 'actualMilking',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let actualMilking = parseFloat(removeCommas(value));

      const { siteInfo } = dataRecord;
      let { dairySite } = siteInfo;

      if(!actualMilking) {
        actualMilking = 0;
      } 

      if(!dairySite) {
        dairySite = 1;
      } else {
        dairySite = parseFloat(removeCommas(dairySite));
      }

      const cowDairySiteAc = actualMilking / dairySite
      await saveRecord('siteInfo','cowDairySiteAc', cowDairySiteAc, id, record, true);


      let { priceSale, dairyImprovements, id:transactionalId } = dataRecord.transactional;
      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }
      if(!dairyImprovements) {
        dairyImprovements = 0;
      } else {
        dairyImprovements = parseFloat(removeCommas(dairyImprovements));
      }

      const priceMilkingCow = priceSale/actualMilking;
      const improvementsCow = dairyImprovements/actualMilking;
      await saveRecord('transactional', 'priceMilkingCow', priceMilkingCow, transactionalId, record, true);
      await saveRecord('transactional', 'improvementsCow', improvementsCow, transactionalId, record, true);

    }
  },
  {
    label: 'Cow/Dairy Site Acre',
    value: 'cowDairySiteAc',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: 'Cows/DSA',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Soils',
    value: 'soils',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Ground Water',
    value: 'groundWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Surface Water',
    value: 'surfaceWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Permit',
    value: 'permit',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Use',
    value: 'use',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Open Ground',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Plantings',
    value: 'plantings',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Permanent Plantings',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Irrigation System',
    value: 'irrigationSystem',
    type: 'text',
    subCategories: [
      'Permanent Plantings',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Utilities',
    value: 'utilities',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Zoning',
    value: 'zoning',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Orientation',
    value: 'orientation',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
];

export default siteInfoSchema
