import { getRule } from '@/utils/rules';
const attachmentsSchema = [
  {
    label: 'Name',
    value: 'name',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Data',
    value: 'fieldData',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
];
export default attachmentsSchema;
