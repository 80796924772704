import { getRule, removeCommas } from '@/utils/rules';
const leaseInformationSchema = [
  {
    label: 'Tenant',
    value: 'tenant',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Suite Size',
    value: 'suiteSize',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: 'SF',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Term',
    value: 'term',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: 'months',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Start Date',
    value: 'startDate',
    type: 'datepicker',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Options',
    value: 'options',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Lease Type',
    value: 'leaseType',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Rent/Month',
    value: 'rentMonth',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/mo',
    saleTypes: ['Sale','Lease'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      console.log('vvvvvvvvvvvCALCvvvvvvvvvvvvvvvvvv');
      const { leaseInformation:dataItem } = dataRecord;
      if(dataItem) {
        const itemRecord = dataItem.reduce((i, j) => {
          if(j.id === id) {
            return j;
          }
          return i;
        }, null);
        if(itemRecord) {
          console.log('GOT ITEM RECORD');
          let { suiteSize, rentMonth } = itemRecord;
          if(!suiteSize) {
            suiteSize = 0;
          } else {
            suiteSize = parseInt(removeCommas(suiteSize));
          }

          if(!rentMonth) {
            rentMonth = 0;
          } else {
            rentMonth = parseFloat(removeCommas(rentMonth));
          }
          
          console.log(`suiteSize: ${suiteSize}`);
          console.log(`rentMonth: ${rentMonth}`);
          

          const newValue = parseInt((rentMonth / suiteSize) * 100) / 100;
          await saveRecord('leaseInformation', 'rentSF', newValue, id, record, true);
        }
        console.log('^^^^^^^^^^^CALC^^^^^^^^^^^^^^^^^^');
      }
    }
  },
  {
    label: 'Rent/SF',
    value: 'rentSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale','Lease'],
    editable: false,
  },
  {
    label: 'Increases',
    value: 'increases',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },

];
export default leaseInformationSchema;
