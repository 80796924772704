import { removeCommas } from '@/utils/rules';
export const calcValues = async(value, id, record, saveRecord, dataRecord) => {
      console.log('CALC Contributing Value');
      console.log(value);
      console.log(id);
      console.log(record);
      console.log(saveRecord);
      console.log(dataRecord);
      const { improvementItems, transactional, siteInfo } = dataRecord;
      let { priceSale,  plantingsLandValue, ogLandValue, dairyLandValue, id:transactionalId } = transactional;
      let { plantedAcres, totalAcres, actualMilking, id:siteInfoId } = siteInfo;

      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }

      if(!totalAcres) {
        totalAcres = 0;
      } else {
        totalAcres = parseFloat(removeCommas(totalAcres));
      }

      if(!plantedAcres) {
        plantedAcres = 1;
      } else {
        plantedAcres = parseFloat(removeCommas(plantedAcres));
      }

      if(!actualMilking) {
        actualMilking = 1;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }

      if(!dairyLandValue) {
        dairyLandValue = 1;
      } else {
        dairyLandValue = parseFloat(removeCommas(dairyLandValue));
      }

  

      let landValue = parseFloat(removeCommas(plantingsLandValue));
      if(!landValue) {
        landValue = parseFloat(removeCommas(ogLandValue));
      } 

      if(!landValue) {
        landValue = 0;
      } 



  let houseValue = 0;
  let otherValue = 0;

      if(improvementItems && improvementItems.length) {

        const { myHouseValue, myOtherValue } = improvementItems.reduce((i, j) => {
          const { type, contributingValue } = j;
          if(type.toLowerCase() === 'house') {
            i.myHouseValue += parseFloat(removeCommas(contributingValue));
          } else {
            i.myOtherValue += parseFloat(removeCommas(contributingValue));
          }
          return i;
        }, { myHouseValue: 0, myOtherValue: 0});
        console.log(`houseValue: ${houseValue}`);
        console.log(`otherValue: ${otherValue}`);
        otherValue = myOtherValue;
        houseValue = myHouseValue;
      }
  const improvedOrchardValue = priceSale - otherValue - houseValue;
  const pricePerPlantedAcre = improvedOrchardValue / plantedAcres;
  const landValuePerAcre = priceSale / totalAcres;

  
  const dairyImprovements = priceSale - otherValue - dairyLandValue - houseValue;
  await saveRecord('transactional', 'dairyImprovements', dairyImprovements, transactionalId, record, true);


  console.log(`actualMilking: ${actualMilking}`);
  if(actualMilking) {
    const priceMilkingCow = priceSale/actualMilking;
    const improvementsCow = dairyImprovements/actualMilking;
    await saveRecord('transactional', 'priceMilkingCow', priceMilkingCow, transactionalId, record, true);
    await saveRecord('transactional', 'improvementsCow', improvementsCow, transactionalId, record, true);
  }


  await saveRecord('siteInfo', 'landValuePerAcre', landValuePerAcre, siteInfoId, record, true);
  await saveRecord('siteInfo', 'pricePerPlantedAcre', pricePerPlantedAcre, siteInfoId, record, true);
  await saveRecord('transactional', 'otherValue', otherValue , transactionalId, record, true)
  await saveRecord('transactional', 'houseValue', houseValue , transactionalId, record, true)
  if(plantingsLandValue) {
    await saveRecord('transactional', 'plantingsLandValue', improvedOrchardValue , transactionalId, record, true)
  }
  if(ogLandValue) {
    await saveRecord('transactional', 'ogLandValue', improvedOrchardValue , transactionalId, record, true)
  }
}
