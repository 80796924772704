<template>
  <v-card class="ma-6">
    <v-row class="mb-5 topRow" dense>
      <v-col cols="10" class="pl-4 heading" dense>
        {{ title }}
      </v-col>
      <v-col cols="1">
        <v-tooltip v-if="iData.length" bottom color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              outlined
              color="blue darken-4"
              v-bind="attrs"
              v-on="on"
              @click="$emit('addCustomField')"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          Add Custom {{ title }} Fields
        </v-tooltip>


        <v-tooltip v-else bottom color="grey darken-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          Add a Row to create Custom fields
        </v-tooltip>


      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" dense style="margin-top: -20px">
        <v-progress-linear
          absolute
          :active="loading"
          indeterminate
          color="blue darken-4"
          value="100"
        ></v-progress-linear>
        <div
          v-if="!loading"
          style="
            width: 100%;
            height: 2px;
            background: #496688;
            margin-top: 1px 0 1px;
          "
        ></div>
      </v-col>
    </v-row>
    <v-row
      dense
      :class="{
        errorSection: true,
        active: errorStatus !== '',
        inactive: errorStatus === '',
      }"
      style="overflow-x:auto;"
    >
      <v-col cols="10" class="pl-4 pt-2" style="color: red">
        {{ errorStatus !== "" ? `Error: ${errorStatus}` : "" }}
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="errorStatus !== ''"
              icon
              @click="$emit('emitError', '')"
              color="red darken-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          Close Error
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" dense>
        <v-data-table
          :headers="headers"
          :items="iData"
          multi-sort
          :hide-default-footer="true"
        >
          <template v-slot:footer>
            <v-btn color="primary" text outlined class="ma-2" @click="addRow">
              Add Row
            </v-btn>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, li) in items" :key="li">
              <td v-for="(schemaItem, lj) in schema" :key="lj">
                <v-checkbox
                  v-if="schemaItem.type === 'checkbox'"
                  v-model="item[schemaItem.value]"
                  :label="schemaItem.label"
                  dense
                  class="pa-1 ma-1"
                  @change="updateRecord( schemaItem.value, item.id, item[schemaItem.value] )"
                  :disabled="'editable' in item && !item.editable"
                ></v-checkbox>
                <v-menu
                  v-else-if="schemaItem.type === 'datepicker'"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  :return-value.sync="schemaItem.value"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :lazy="true"
                  :disabled="'editable' in item && !item.editable"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item[schemaItem.value]"
                      :label="schemaItem.label"
                      append-icon="mdi-calendar-plus"
                      outlined
                      dense
                      class="pa-1 ma-1"
                      v-bind="attrs"
                      @click:append="on.click"
                      v-on="on"
                      @change="updateRecord( schemaItem.value, item.id, item[schemaItem.value] )"
                      :disabled="'editable' in item && !item.editable"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item[schemaItem.value]"
                    no-title
                    scrollable
                    @change="updateRecord( schemaItem.value, item.id, item[schemaItem.value] )"
                   :disabled="'editable' in item && !item.editable"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-else
                  v-model="item[schemaItem.value]"
                  :rules="schemaItem.rules"
                  :label="schemaItem.label"
                  :prefix="schemaItem.prefix"
                  :suffix="schemaItem.suffix"
                  outlined
                  dense
                  class="pa-1 ma-1"
                  style="min-width: 120px"
                  @change="updateRecord( schemaItem.value, item.id, item[schemaItem.value] )"
                  :disabled="'editable' in schemaItem && !schemaItem.editable"
                ></v-text-field>
              </td>
              <td
                v-for="(cItem, lk) in item.custom"
                :key="lk + schema.length"
                dense
              >
                <v-checkbox
                  v-if="cItem.type === 'checkbox'"
                  v-model="cItem.value"
                  :label="cItem.label"
                  dense
                  class="pa-1 ma-1"
                  @change="updateRecord( cItem.value, item.id, cItem.value )"
                ></v-checkbox>
                <v-menu
                  v-else-if="cItem.type === 'datepicker'"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  :return-value.sync="cItem.value"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :lazy="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="cItem.value"
                      :label="cItem.label"
                      append-icon="mdi-calandar-plus"
                      outlined
                      dense
                      class="pa-1 ma-1"
                      v-bind="attrs"
                      v-on="on"
                      @change="updateRecord( cItem.value, item.id, cItem.value )"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="cItem.value"
                    no-title
                    scrollable
                    @change="updateRecord( cItem.value, item.id, cItem.value )"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-else
                  v-model="cItem.value"
                  :rules="cItem.rules"
                  :label="cItem.name"
                  :prefix="cItem.prefix"
                  :suffix="cItem.suffix"
                  outlined
                  dense
                  class="pa-1 ma-1"
                  @change="$emit('updateCustomField', cItem)"
                ></v-text-field>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "TableView",
  props: {
    data: {
      type: Array,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Title Goes Here",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorStatus: {
      type: String,
      default: "",
    },
    schemaName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const iData = ref([...props.data]);
    const buildCustomHeaders = () => {
      const cData = [];
      const { data } = props;
      if (data && data.length) {
        //console.log("got data");
        const { custom } = data[0];
        if (custom) {
          //console.log("mapping custom");
          return custom.map((i) => {
            console.log("handle record");
            return {
              label: i.name,
              value: `custom_${i.name}`,
              type: i.type,
              rules: [],
            };
          });
        } else {
          //console.log("no custom");
          console.log(data);
        }
      } else {
        //console.log("no data");
      }
      return cData;
    };

    const getCustomHeaders = ref(buildCustomHeaders());
    console.log("got getCustomHeaders");
    console.log(getCustomHeaders.value);
    const headers = ref(
      [...props.schema, ...getCustomHeaders.value].map((i) => {
        console.log("headers");
        console.log(i);
        return {
          text: i.label,
          value: i.value,
          sortable: true,
          width: "200px",
        };
      })
    );

    const emitCustomItem = (item) => {
      console.log("emit Custom Item");
      console.log(item);
    };

    const addRow = () => {
      console.log(`addRow ${props.schemaName}`);
      emit("addTableRow", props.schemaName);
    };

    const updateRecord = (field, index, value) => {
      const recordObj = iData.value.reduce(
        (j, i) => (i.id === index ? i : j),
        null
      );
      const recordId = recordObj.id;
      emit("update", field, value, index, recordId);
    };

    watch(
      () => props.data,
      () => {
        iData.value = [...props.data];
        getCustomHeaders.value = buildCustomHeaders();
        headers.value = [...props.schema, ...getCustomHeaders.value].map(
          (i) => {
            console.log("I");
            console.log(i);
            return {
              text: i.label,
              value: i.value,
              sortable: true,
              width: "200px",
            };
          }
        );
      },
      {
        deep: true,
      }
    );

    return {
      getCustomHeaders,
      updateRecord,
      iData,
      headers,
      emitCustomItem,
      addRow,
    };
  },
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {
    height: 0px;
    opacity: 100;
  }
  to {
    height: 60px;
    opacity: 0;
  }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 60px;
  opacity: 100;
}
@keyframes inactiveError {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 60px;
    opacity: 100;
  }
}
</style>
