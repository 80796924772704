import { render, staticRenderFns } from "./bubbleView.vue?vue&type=template&id=629a70ed&scoped=true&"
import script from "./bubbleView.vue?vue&type=script&lang=js&"
export * from "./bubbleView.vue?vue&type=script&lang=js&"
import style0 from "./bubbleView.vue?vue&type=style&index=0&id=629a70ed&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629a70ed",
  null
  
)

export default component.exports