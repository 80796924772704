import { getRule } from '@/utils/rules';
const improvementsSchema = [
  {
    label: 'Gross Building Area',
    value: 'grossBuildingArea',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
		],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Net Rentable Area',
    value: 'netRentableArea',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
		],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Year Built',
    value: 'yearBuilt',
    type: 'year',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
			'Dairy',
      'Self Storage',
		],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
	{
    label: 'Condition',
    value: 'condition',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Percent Finished',
    value: 'percentFinished',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Retail',
      'Shopping',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Percent Office',
    value: 'percentOffice',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Medical',
      'Office',
      'Restaurant',
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Num Units',
    value: 'numUnits',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
		],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Num Stories',
    value: 'numStories',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
		],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Sprinklers',
    value: 'sprinklers',
    type: 'checkbox',
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
		],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Loading Dock',
    value: 'loadingDock',
    type: 'checkbox',
    subCategories: [
      'Industrial',

		],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Drive Thru',
    value: 'driveThru',
    type: 'checkbox',
    subCategories: [
      'Restaurant',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Ameninities',
    value: 'ameninities',
    type: 'textarea',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Property Features',
    value: 'propertyFeatures',
    type: 'textarea',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'test',
    ],
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Parking Stalls',
    value: 'parkingStalls',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Parking Ratio',
    value: 'parkingRatio',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Medical',
      'Office',
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Building Class',
    value: 'buildingClass',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Facility Type',
    value: 'facilityType',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Milk Barn',
    value: 'milkBarn',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
	{
    label: 'Feed Storage',
    value: 'feedStorage',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Solar',
    value: 'solar',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
	{
    label: 'Digester',
    value: 'digester',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Residences',
    value: 'residences',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
  {
    label: 'Effective Age',
    value: 'effectiveAge',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale','Lease'],
  },
];
export default improvementsSchema;
