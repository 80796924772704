import { getRule, addCommas, removeCommas } from '@/utils/rules';
import { calcValues } from '@/utils/calcs.js';

const transactionalSchema = [
  {
    label: 'Lessee',
    value: 'lessee',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lessor',
    value: 'lessor',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lease Hold',
    value: 'leaseHold',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rental Rate',
    value: 'rentalRate',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Monthly Rent',
    value: 'monthlyRent',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rent Per SQFT',
    value: 'rentPerSqft',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Term',
    value: 'term',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },




  {
    label: 'Buyer',
    value: 'buyer',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Seller',
    value: 'seller',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Recording Date',
    value: 'recordingDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Sale Date',
    value: 'saleDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Doc Number',
    value: 'docNumber',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Marketing Time',
    value: 'marketingTime',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Broker',
    value: 'broker',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Confirm Date',
    value: 'confirmDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Occupancy',
    value: 'occupancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale'],
  },
  {
    label: 'Rights',
    value: 'rights',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/SF',
    value: 'priceSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial' ,'Shopping',
      'Self Storage',
    ],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Unit',
    value: 'priceUnit',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Apartment',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Self Storage',
      'test',
    ],
    prefix: '$',
    suffix: '/Unit',
    saleTypes: ['Sale'],
  },
  {
    label: 'Sale Price',
    value: 'priceSale',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      console.log(dataRecord.siteInfo);
      const valNum = parseFloat(removeCommas(value));
      let { totalAcres, actualMilking } = dataRecord.siteInfo;
      let { otherValue, dairyLandValue, houseValue } = dataRecord.transactional;
      if(!totalAcres) {
        totalAcres = 0;
      } else {
        totalAcres = parseFloat(removeCommas(totalAcres));
      }

      if(!otherValue) {
        otherValue = 0;
      } else {
        otherValue = parseFloat(removeCommas(otherValue));
      }

      if(!dairyLandValue) {
        dairyLandValue = 0;
      } else {
        dairyLandValue = parseFloat(removeCommas(dairyLandValue));
      }


      if(!houseValue) {
        houseValue = 0;
      } else {
        houseValue = parseFloat(removeCommas(houseValue));
      }

      if(!actualMilking) {
        actualMilking = 1;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }



      console.log('set  acre price');
      console.log(totalAcres);
      console.log(valNum);


      const priceSale= removeCommas(value)

      let { aNoi } = dataRecord.apartmentFinancial;
      let { pNoi } = dataRecord.apartmentFinancial;


      const apartmentFinancialID = dataRecord.apartmentFinancial.id;
      const aCapRate = (removeCommas(aNoi) / removeCommas(priceSale)) * 100;
      const pCapRate = (removeCommas(pNoi) / removeCommas(priceSale)) * 100;
      console.log('calc a cap rate');
      console.log(`new aCapRate rate ${aCapRate} = ${removeCommas(aNoi)} / ${removeCommas(priceSale)}`);
      console.log(`new pCapRate rate ${pCapRate} = ${removeCommas(pNoi)} / ${removeCommas(priceSale)}`);
      await saveRecord('apartmentFinancial', 'pCAPRate', pCapRate, apartmentFinancialID, record, true);
      await saveRecord('apartmentFinancial', 'aCAPRate', aCapRate, apartmentFinancialID, record, true);


      if(totalAcres) {
        console.log(valNum / totalAcres);
        await saveRecord('transactional', 'priceAcre', addCommas(valNum / totalAcres), id, record, true);
      } else {
        console.log('do zero');
        await saveRecord('transactional', 'priceAcre', addCommas(valNum), id, record, true);
      }

      console.log(`actualMilking: ${actualMilking}`);

      calcValues(value, id, record, saveRecord, dataRecord);
      const dairyImprovements = priceSale - otherValue - dairyLandValue - houseValue;
      await saveRecord('transactional', 'dairyImprovements', dairyImprovements, id, record, true);
      if(actualMilking) {
        const priceMilkingCow = priceSale/actualMilking;
        const improvementsCow = dairyImprovements/actualMilking;
        await saveRecord('transactional', 'priceMilkingCow', priceMilkingCow, id, record, true);
        await saveRecord('transactional', 'improvementsCow', improvementsCow, id, record, true);
      }
    },
  },
  {
    label: 'Price Adj',
    value: 'priceAdj',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial' ,
      'Self Storage',
      'Open Ground',
      'Permanent Plantings',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Other',
    value: 'otherValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    editable: false,
    /*
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let { priceSale, otherValue, landValue, houseValue } = dataRecord.transactional;
      let { actualMilking } = dataRecord.siteInfo;
      if(!actualMilking) {
        actualMilking = 0;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }

      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }


      if(!otherValue) {
        otherValue = 0;
      } else {
        otherValue = parseFloat(removeCommas(otherValue));
      }

      if(!landValue) {
        landValue = 0;
      } else {
        landValue = parseFloat(removeCommas(landValue));
      }

      if(!houseValue) {
        houseValue = 0;
      } else {
        houseValue = parseFloat(removeCommas(houseValue));
      }

      const dairyImprovements = priceSale - otherValue - landValue - houseValue;
      await saveRecord('transactional', 'dairyImprovements', dairyImprovements, id, record, true);
      const improvementsCow = dairyImprovements/actualMilking;
      await saveRecord('transactional', 'improvementsCow', improvementsCow, id, record, true);
    },
    //*/
  },

  {
    label: 'Land Value',
    value: 'dairyLandValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      let landValue = parseFloat(removeCommas(value));
      let { priceSale, otherValue, houseValue } = dataRecord.transactional;
      let { totalAcres, id:siteInfoId } = dataRecord.siteInfo;

      if(!totalAcres) {
        totalAcres = 1;
      } else {
        totalAcres = parseFloat(removeCommas(totalAcres));
      } 

      let { actualMilking } = dataRecord.siteInfo;
      if(!actualMilking) {
        actualMilking = 0;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }

      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }


      if(!otherValue) {
        otherValue = 0;
      } else {
        otherValue = parseFloat(removeCommas(otherValue));
      }

      if(!landValue) {
        landValue = 0;
      } 

      if(!houseValue) {
        houseValue = 0;
      } else {
        houseValue = parseFloat(removeCommas(houseValue));
      }

      const landValuePerAcre = landValue / totalAcres;
      await saveRecord('siteInfo', 'landValuePerAcre', landValuePerAcre, siteInfoId, record, true)

      const dairyImprovements = priceSale - otherValue - landValue - houseValue;
      await saveRecord('transactional', 'dairyImprovements', dairyImprovements, id, record, true);
      const improvementsCow = dairyImprovements/actualMilking;
      await saveRecord('transactional', 'improvementsCow', improvementsCow, id, record, true);
    },

  },

  {
    label: 'Improved Orchard Value',
    value: 'plantingsLandValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Permanent Plantings',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },

  {
    label: 'Land Value',
    value: 'ogLandValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Open Ground',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    editable: false,    

  },

  {
    label: 'House Value',
    value: 'houseValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    editable: false,
    /*
    calc: async (value, id, record, saveRecord, dataRecord) => {

      let { priceSale, otherValue, landValue, houseValue } = dataRecord.transactional;

      let { actualMilking } = dataRecord.siteInfo;
      if(!actualMilking) {
        actualMilking = 0;
      } else {
        actualMilking = parseFloat(removeCommas(actualMilking));
      }


      if(!priceSale) {
        priceSale = 0;
      } else {
        priceSale = parseFloat(removeCommas(priceSale));
      }

      if(!otherValue) {
        otherValue = 0;
      } else {
        otherValue = parseFloat(removeCommas(otherValue));
      }

      if(!landValue) {
        landValue = 0;
      } else {
        landValue = parseFloat(removeCommas(landValue));
      }

      if(!houseValue) {
        houseValue = 0;
      } else {
        houseValue = parseFloat(removeCommas(houseValue));
      }

      const dairyImprovements = priceSale - otherValue - landValue - houseValue;
      console.log(Object.keys(dataRecord.transactional));
      console.log(`dairyImprovements: ${priceSale - otherValue - landValue - houseValue} = ${priceSale} - ${otherValue} - ${landValue} - ${houseValue}`);
      await saveRecord('transactional', 'dairyImprovements', dairyImprovements, id, record, true);
      const improvementsCow = dairyImprovements/actualMilking;
      await saveRecord('transactional', 'improvementsCow', improvementsCow, id, record, true);
    },
    //*/
  },
  {
    label: 'Dairy Improvements',
    value: 'dairyImprovements',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    editable: false,
  },
  {
    label: 'Price/Acre',
    value: 'priceAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale'],
    editable: false,
  },
  {
    label: 'Price/Milking Cow',
    value: 'priceMilkingCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Milking Cow',
    saleTypes: ['Sale'],
    editable: false,
  },
  {
    label: 'Improvements/Cow',
    value: 'improvementsCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Cow',
    saleTypes: ['Sale'],
    editable: false,
  },
  {
    label: 'Confirmation Source',
    value: 'confirmationSource',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale', 'Lease'],
  },
];
export default transactionalSchema;

/*
import { getRule, addCommas, removeCommas } from '@/utils/rules';
const transactionalSchema = [

  {
    label: 'Lessee',
    value: 'lessee',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lessor',
    value: 'lessor',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Lease Hold',
    value: 'leaseHold',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rental Rate',
    value: 'rentalRate',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Monthly Rent',
    value: 'monthlyRent',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Rent Per SQFT',
    value: 'rentPerSqft',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },
  {
    label: 'Term',
    value: 'term',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Lease'],
  },




  {
    label: 'Buyer',
    value: 'buyer',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Seller',
    value: 'seller',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Recording Date',
    value: 'recordingDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Doc Number',
    value: 'docNumber',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Marketing Time',
    value: 'marketingTime',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Broker',
    value: 'broker',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Confirm Date',
    value: 'confirmDate',
    type: 'datepicker',
    subCategories: ['*'],
    saleTypes: ['Sale'],
  },
  {
    label: 'Occupancy',
    value: 'occupancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
    saleTypes: ['Sale'],
  },
  {
    label: 'Rights',
    value: 'rights',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/SF',
    value: 'priceSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial' ,'Shopping',
      'Self Storage',
      'Dairy',
      'Permenant Plantings'
    ],
    prefix: '$',
    suffix: '/SF',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Unit',
    value: 'priceUnit',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/Unit',
    saleTypes: ['Sale'],
  },
  {
    label: 'Sale Price',
    value: 'priceSale',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
    calc: async (value, id, record, saveRecord, dataRecord) => {
      console.log(dataRecord.siteInfo);
      const valNum = parseFloat(removeCommas(value));
      let { totalAcres } = dataRecord.siteInfo;
      if(!totalAcres) {
        totalAcres = 0;
      } else {
        totalAcres = parseFloat(removeCommas(totalAcres));
      }
      console.log('set  acre price');
      console.log(totalAcres);
      console.log(valNum);
      if(totalAcres) {
        console.log(valNum / totalAcres);
        await saveRecord('transactional', 'priceAcre', addCommas(valNum / totalAcres), id, record, true);
      } else {
        console.log('do zero');
        await saveRecord('transactional', 'priceAcre', addCommas(valNum), id, record, true);
      }
    },
  },
  {
    label: 'Price Adj',
    value: 'priceAdj',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Land Value',
    value: 'landValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'House Value',
    value: 'houseValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Dairy Improvements',
    value: 'dairyImprovements',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Acre',
    value: 'priceAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
    saleTypes: ['Sale'],
  },
  {
    label: 'Price/Milking Cow',
    value: 'priceMilkingCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Milking Cow',
    saleTypes: ['Sale'],
  },
  {
    label: 'Improvements/Cow',
    value: 'improvementsCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Cow',
    saleTypes: ['Sale'],
  },
];
export default transactionalSchema;
*/
