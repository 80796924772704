import { getRule } from '@/utils/rules';
const remarksSchema = [
  {
    label: 'Remarks',
    value: 'remarks',
    type: 'textarea',
    rules: getRule('text'),
    subCategories: ['*'],
    saleTypes: ['Sale','Lease'],
  },
];
export default remarksSchema;
