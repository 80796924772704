<template>
  <v-card
    v-if="displayComponent"
    class="m-5 pt-5 pb-5 f cardContainer z-1"
    :class="{ propertyCardBorder: currentView === 'tableView' }"
    width="500"
    style="cursor: pointer"
  >
    <confirmDialog 
      :showDialog="areYouSure" 
      title="Warning: Deleting Data" 
      description="Are you sure you want delete this?" 
      noText="Nevermind."
      yesText="Yes, I'm sure."
      @no='areYouSure = false' 
      @yes="deleteProperty"
      :yesFirst="false"
      />


  <div style="position:absolute; left: -5000px; right:-5000px; border: 0px; width:0px; height:0px;">
  <input/>
  </div>

    <v-row>
      <v-col md="4" align-self="center">
        <v-img v-if="image" class="ml-4" :src="image" style="border-radius: 8px" contain/>
        <v-img v-else class="ml-4" :src="defaultImage" style="border-radius: 8px" >
          <v-progress-circular
            indeterminate
            v-if="imgLoading"
            color="blue darken-4"
            position="justify"
            alignment="center"
            />
        </v-img>
      </v-col>
      <v-col md="8">
        <v-row cols="12" dense class="justify-end">
          <v-tooltip bottom color="green darken-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                icon
                dense
                @click="$emit('selectCard')"
                color="green darken-4"
                style="float: right"
                v-bind="attrs" v-on="on"
                >
                <v-icon v-if="isSelected">mdi-check</v-icon>
                <v-icon v-else>mdi-select</v-icon>
              </v-btn>
            </template>
            Select Property
          </v-tooltip>
          <v-tooltip bottom color="blue darken-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                icon
                dense
                @click="loadProperty"
                color="blue darken-4"
                style="float: right"
                v-bind="attrs" v-on="on"
                >
                <v-progress-circular indeterminate size="20" v-if="loadingData"></v-progress-circular>
                <v-icon v-else class="">mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
            Edit Property
          </v-tooltip>
          <v-tooltip bottom color="red darken-4">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                icon
                dense
                @click="areYouSure = true"
                color="red darken-4"
                style="float: right"
                v-bind="attrs" v-on="on"
                >
                <v-icon class="">mdi-delete</v-icon>
              </v-btn>
            </template>
            Delete Property
          </v-tooltip>
          <v-tooltip v-if="tableData" bottom color="black darken-4">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-3"
              icon
              dense
              @click="$emit('close')"
              color="black darken-4"
              style="float: right"
                v-bind="attrs" v-on="on"
            >
              <v-icon class="">mdi-close</v-icon>
            </v-btn>
            </template>
            Close
          </v-tooltip>
        </v-row>

        <v-row dense>
          <v-col
            :md="tableData ? 11 : 12"
            color="black"
            style="font-weight: 800"
          >
            {{ address }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="5">
            {{ saleDate }}
          </v-col>
          <v-col md="5"> PPA {{ salePrice }} </v-col>
        </v-row>
        <v-row dense>
          <v-col md="5">
            {{ otherData }}
          </v-col>
          <v-col md="5"> {{ acreage }} Acres </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { 
  defineComponent,
  ref,
  watch,
} from "vue";
import defaultImage from "@/assets/default-product.jpg";
import confirmDialog from '@/components/general/confirmDialog';
import { api } from '@/services/api-service';

export default defineComponent({
  name: "propertyCard",
  components: {
    confirmDialog,
  },
  props: {
    id: {
      type: [String, Number],
    },
    displayComponent: {
      type: Boolean,
      default: false,
    },
    saleDate: {
      type: String,
      default: "No Date",
    },
    salePrice: {
      type: String,
      default: "No Price",
    },
    acreage: {
      type: String,
      default: "N/A",
    },
    address: {
      type: String,
      default: "No Address",
    },
    otherData: {
      type: String,
      default: "",
    },
    imageId: {
      type: String,
      default: null,
    },
    tableData: {
      type: Boolean,
      default: false,
    },
    currentView: {
      type: String,
      default: "mapView",
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    loadingData: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    console.log('setup');
    const closeConditional = (event) => {
      console.log("closeConditional");
      console.log(event);
    };
    const isSelected = ref(false);

    const imgLoading = ref(false);
    const areYouSure = ref(false);
    const getImage = (imageId) => {
      imgLoading.value = true;
      image.value = null;
      console.log('get image');
      api.getImage(imageId)
      .then((imageRecord) => {
        console.log('done');
        console.log(imageRecord);
        const { data } = imageRecord;
        if(data) {
          console.log('got data');
          console.log(data);
          const { fileData } = data;
          if(fileData) {
            console.log('set actual image');
            image.value = fileData;
            return;
          }
        }
        image.value = defaultImage;
        imgLoading.value = true;
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
        image.value = defaultImage;
        imgLoading.value = true;
      });
    }

    const image = ref();
    if(props.imageId) {
      getImage(props.imageId);
    } else {
      console.log('no image');
      console.log(props);
    }
    const loadProperty = async () => {
      await emit('clickCard');
    };
    const deleteProperty = async() => {
      await emit('deleteCard');
    }
    watch(props, (newprops) => {
      console.log('change propertyCard');
      console.log(props);
      console.log(`new props: ${newprops.imageId}`);
      if(props.imageId) {
        console.log(`image ${props.imageId}`);
        getImage(props.imageId);
      } else {
        image.value = defaultImage;
      }
      console.log('selected items');
      console.log(props.selectedItems);
      isSelected.value =  props.selectedItems.reduce((i, j) => {
        console.log('computing selected item');
        console.log(j);

        if(j === props.id) {
          console.log('SELECT ID');
          console.log('');
          return true;
        }
        console.log('');
        return i;
      }, false);

    }); 


    return {
      deleteProperty,
      imgLoading,
      getImage,
      closeConditional,
      defaultImage,
      image,
      loadProperty,
      areYouSure,
      isSelected,
    };
  },
  //box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
});
</script>
<style lang="scss" scoped>
.cardContainer:before {
  background:white;
}
.cardContainer:after {
  content: "";
  position: absolute;
  background: white;
  width: 15px;
  height: 15px;
  left: 155px;
  bottom: -7px;
  transform: rotate(45deg);
  box-shadow: 0px 3px 0px 0px rgb(0 0 0 / 20%), 3px 3px 0px 0px rgb(0 0 0 / 14%);
}

.propertyCardBorder {
  border: 1px solid #bebebe;
}
</style>
